import React from 'react';
import PropTypes from 'prop-types';
import ReactAlert from 'react-s-alert';

import Formsy from 'formsy-react';

import {inject, observer} from 'mobx-react';
import {translate, Trans} from 'react-i18next';

import FormModel from '../../../../models/FormModel';

import Input from '../../../shared/form-elements/Input';
import Select from '../../../shared/form-elements/Select';
import Checkbox from '../../../shared/form-elements/Checkbox';
import Textarea from '../../../shared/form-elements/Textarea';

import {
  errorMessages as err,
  searchLabels,
  alertConfig,
} from '../../../../constants/common';

import { apiURLs } from '../../../../configs/apiURLs';

import API from '../../../../utils/API';
import Logger from '../../../../utils/Logger';

import '../../../../utils/FormsyCustomValidationRules';

import './styles/ShippingForm.css';

const wrappedPropTypes = {
  t: PropTypes.func.isRequired,
  appStore: PropTypes.object.isRequired,
  modalsStore: PropTypes.object.isRequired,
  submitGameStore: PropTypes.object.isRequired,
};

class ShippingForm extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FormModel({
      fullName: '',
      businessName: '',
      phone: '',
      country: '',
      zip: '',
      city: '',
      state: '',
      address: '',
      saveAddress: false,
    });
  }

  componentDidMount() {
    this.getSelectData();
    this.updateFormState();
  }

  getSelectData = async () => {
    const {appStore} = this.props;

    if (!!appStore.countries.length) return;

    try {
      let response = await API.getData(apiURLs.countries);

      appStore.updateCountries(response.data.items);
    } catch(error) {
      Logger.error(error);
      ReactAlert.error(
        error.hasOwnProperty('message') ? error.message : error,
        alertConfig
      );
    }
  }

  updateFormState = () => {
    const {props} = this;
    const {
      user,
      profile,
    } = props.appStore;
    const {shippingFormData} = props.cartStore;

    let formData = {};

    const getAddressFormItem = (formItemName) => {
      return (!!profile.shippingAddress && !!profile.shippingAddress[formItemName]) ?
        profile.shippingAddress[formItemName] :
        profile.address[formItemName];
    }

    if (!props.cartStore.isDataFromCartStore) {
      formData = {
        fullName: user.fullName,
        businessName: getAddressFormItem('businessName'),
        phone: getAddressFormItem('phone'),
        country: getAddressFormItem('country'),
        zip: getAddressFormItem('zip'),
        city: getAddressFormItem('city'),
        state: getAddressFormItem('state'),
        address: getAddressFormItem('address'),
      };

    } else {
      formData = {
        fullName: shippingFormData.fullName,
        businessName: shippingFormData.businessName,
        phone: shippingFormData.phone,
        country: shippingFormData.country,
        zip: shippingFormData.zip,
        city: shippingFormData.city,
        state: shippingFormData.state,
        address: shippingFormData.address,
      };
    }

    this.store.update(formData);
  }

  onValidSubmit = (model, resetForm, invalidateForm) => {
    const {
      cartStore,
    } = this.props;

    cartStore.updateShippingFormData(this.store.convertToJS());
    cartStore.updateIsDataFromCartStore(true);
    cartStore.updateCartStep(2);

    const { shippingFormData } = cartStore;

    if (
      !!shippingFormData.country.id
    ) {
      cartStore.updateShippingCountry(shippingFormData.country.id);
    }

  }

  render() {
    const {
      t,
      appStore,
    } = this.props;

    const {store} = this;

    return (
      <div className="shipping-form">
        <Formsy
          ref={(component) => {
            store.updateRefToForm(component);
          }}
          onValidSubmit={this.onValidSubmit}>

          <div className="row">

            <div className="col-xs-12">
              <Input
                id="message"
                name="message"
                type="hidden"
                className="form-field--no-margin"
              />
            </div>

            <div className="col-xs-12 shipping-form__item">
              <Input
                name="fullName"
                type="text"
                value={store.elements.get('fullName')}
                updateStateData={store.updateFormItem}
                id="shipping_fullName"
                iconClassName="icon-user"
                placeholder={t('Your Full Name')}
                required
                validations={{
                  isWhitespace: true,
                }}
                validationErrors={{
                  isWhitespace: err.isWhitespace,
                  isDefaultRequiredValue: err.isDefaultRequiredValue,
                }}
              >
                <label htmlFor="shipping_fullName"
                  className="shipping-form__label">
                  <Trans i18nKey={"Your Full Name (or name of recipient)"}>
                    Your Full Name <span>(or name of recipient)</span>
                  </Trans>:
                </label>
              </Input>
            </div>
            <div className="col-xs-12 shipping-form__item">
              <Input
                name="businessName"
                type="text"
                value={store.elements.get('businessName')}
                updateStateData={store.updateFormItem}
                id="shipping_businessName"
                iconClassName="icon-building"
                placeholder={t('Business Name')}
                validations={{
                  isWhitespace: true,
                }}
                validationErrors={{
                  isWhitespace: err.isWhitespace,
                  isDefaultRequiredValue: err.isDefaultRequiredValue,
                }}
              >
                <label htmlFor="shipping_businessName"
                  className="shipping-form__label">
                  <Trans i18nKey={"Business Name"}>
                    Business Name
                  </Trans>
                  {' '}
                  <span>{t('(optional)')}:</span>
                </label>
              </Input>
            </div>

            <div className="col-xs-12 shipping-form__item">
              <div className="row">

                <div className="col-xs-12 col-md-7">
                  <Input
                    name="phone"
                    type="tel"
                    value={store.elements.get('phone')}
                    updateStateData={store.updateFormItem}
                    id="shipping_phone"
                    iconClassName="icon-phone"
                    placeholder={t('Phone number')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  >
                    <label htmlFor="shipping_phone"
                      className="shipping-form__label">
                      <Trans i18nKey={"Phone number"}>
                        Phone number
                      </Trans>:
                    </label>
                  </Input>
                </div>

              </div>
            </div>

            <div className="col-xs-12 shipping-form__item">

              <Select
                name="country"
                id="profile_country"
                className="profile-form__item"
                autoload={false}
                searchPromptText={t(searchLabels.searchPromptText)}
                noResultsText={t(searchLabels.noResultsText)}
                placeholder={t('Country')}
                multi={false}
                searchable={true}
                clearable={true}
                // simpleValue={true}
                labelKey="name"
                valueKey="id"
                value={store.elements.get('country')}
                disabled={!appStore.countries.length}
                options={appStore.shippingCountriesAsJS()}
                required
                updateStateData={store.updateCountryFormItem}
                validationErrors={{
                  isDefaultRequiredValue: err.isDefaultRequiredValue,
                }}>
                <label
                  htmlFor="profile_country"
                  className="profile__label">
                  <Trans i18nKey={"Country"}>
                    Country
                  </Trans>:
                </label>
              </Select>

            </div>

            <div className="col-xs-12 shipping-form__item">
              <div className="row">

                <div className="col-xs-12 col-md-6 shipping-form__item">
                  <Input
                    name="state"
                    type="text"
                    value={store.elements.get('state')}
                    updateStateData={store.updateStateFormItem}
                    id="shipping_state"
                    disabled={!store.elements.get('country')}
                    placeholder={t('State')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}>
                    <label
                      htmlFor="shipping_state"
                      className="shipping-form__label">
                      <Trans i18nKey={"State"}>
                        State
                      </Trans>:
                    </label>
                  </Input>
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    name="city"
                    type="text"
                    value={store.elements.get('city')}
                    updateStateData={store.updateFormItem}
                    id="shipping_city"
                    disabled={!store.elements.get('state')}
                    placeholder={t('City')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}>
                    <label
                      className="shipping-form__label"
                      htmlFor="shipping_city">
                      <Trans i18nKey={"City"}>
                        City
                      </Trans>:
                    </label>
                  </Input>
                </div>

                <div className="col-xs-12 col-md-6">
                  <Input
                    name="zip"
                    type="text"
                    value={store.elements.get('zip')}
                    updateStateData={store.updateFormItem}
                    id="shipping_zip"
                    placeholder={t('Zip code')}
                    required
                    validations={{
                      isWhitespace: true,
                    }}
                    validationErrors={{
                      isWhitespace: err.isWhitespace,
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}
                  >
                    <label htmlFor="shipping_zip"
                      className="shipping-form__label">
                      <Trans i18nKey={"Zip code"}>
                        Zip code
                      </Trans>:
                    </label>
                  </Input>
                </div>

                <div className="col-xs-12">
                  <label
                    className="shipping-form__label">
                    <Trans
                      htmlFor="shipping_address"
                      i18nKey={"Address"}>
                      Address
                    </Trans>:
                  </label>
                  <Textarea
                    name="address"
                    className="shipping-form__address"
                    value={store.elements.get('address')}
                    updateStateData={store.updateFormItem}
                    id="shipping_address"
                    placeholder={t('Address')}
                    required
                    validationErrors={{
                      isDefaultRequiredValue: err.isDefaultRequiredValue,
                    }}/>
                </div>

                <div className="col-xs-12">
                  <Checkbox
                    checked={store.elements.get('saveAddress')}
                    id="shipping_saveAddress"
                    updateStateData={store.updateFormItem}
                    className="shipping-form__checkbox"
                    checkboxClassName="form-checkbox--black"
                    name="saveAddress">
                    <label htmlFor="shipping_saveAddress">
                      <p className="shipping-form__save">
                        {t('SAVE FOR FUTURE ORDERS')}
                      </p>
                    </label>
                  </Checkbox>
                </div>

                <div className="col-xs-12">
                  <div className="shipping-form__btn-wrapper">
                    <button
                      type="submit"
                      className="btn btn--high btn--md btn--icon-right shipping-form__btn">
                      <span className="btn__text btn__text--black">
                        {t('Proceed')}
                        <i className="btn__icon icon-pix-arrow-rt" />
                      </span>
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </Formsy>
      </div>
    );
  }

}

const WrappedShippingForm = inject(
  'appStore',
  'cartStore',
  'submitGameStore',
  'modalsStore'
)(observer(ShippingForm));

const TranslateShippingForm = translate()(WrappedShippingForm);
TranslateShippingForm.wrappedComponent.propTypes = wrappedPropTypes;

export default TranslateShippingForm;
