import {extendObservable, action, toJS} from 'mobx';
import {
  mergeWith,
  isEqual,
  cloneDeep,
} from 'lodash';

import { regions } from '../constants/regions';

import Helpers from '../utils/Helpers';


class AppStore {
  userInitialState = {
    id: '', // number
    email: '', // string
    fullName: '', // string
    role: '', //string
    isActive: '', //bool
  };

  profileInitialState = {
    address: {
      id: '',
      businessName: '', // string
      zip: '', // number
      city: '', // string
      state: '', // string
      address: '', // string
      phone: '', // string
      country: '',
    },
    shippingAddress: {
      id: '',
      businessName: '', // string
      zip: '', // number
      city: '', // string
      state: '', // string
      address: '', // string
      phone: '', // string
      country: '',
    },
    social: {
      facebook: '', // string url
      instagram: '', // string url
      twitter: '', // string url
      linkedin: '', // string url
    },
    bio: '', // string
    avatar: '',
  };

  constructor() {
    extendObservable(this, {
      user: mergeWith({}, this.userInitialState),
      profile: mergeWith({}, this.profileInitialState),

      countries: [],

      spinnerStatus: false,
      progressStatus: false,
      sidebarStatus: false,

      isAnalyticsInitialised: false,

      get isUserLoggedIn() {
        return !!this.user.id;
      }
    });

    // helpers
    this.convertCountriesToJS = () => {
      return toJS(this.countries);
    };

    this.shippingCountriesAsJS = () => {
      const shippingCountries = toJS(this.countries).filter(item => item.id === '230');
      return shippingCountries;
    }

    this.convertRegionsToJS = () => {
      const countries = toJS(this.countries);
      return countries.concat(regions);
    };

    this.updateUserData = action('update user data', data => {
      const profile = cloneDeep(data.profile);

      this.updateProfileData(profile);

      delete data.profile;

      if (isEqual(toJS(this.user), data)) return;

      Object.keys(data).forEach(key => {
        const value = data[key];

        if (this.user[key] !== value) {
          this.user[key] = !(value === null && typeof value === "object") ? value : '';
        }
      });
    });

    this.updateProfileData = action('update user profile data', data => {
      if (!data || isEqual(toJS(this.profile), data)) return;

      Object.keys(data).forEach(key => {
        const value = data[key];

        if (this.profile[key] !== value) {
          this.profile[key] = !(value === null && typeof value === "object") ? value : '';
        }
      });
    });

    this.updateCountries = action('update countries list', data => {
      const setCoutryToTop = (arr, from, to) => {
        const indexes = arr.map(i => i.id);
        const indexOfCountry = indexes.indexOf(from);
        Helpers.move(data, indexOfCountry, 0);

        return arr;
      };

      data = setCoutryToTop(data, 229, 0); // UK to top
      data = setCoutryToTop(data, 110, 0); // Japan to top
      data = setCoutryToTop(data, 39, 0); // Canada to top
      data = setCoutryToTop(data, 230, 0); // USA to top

      const dataMapped = data.map(item => {
        item.id = item.id.toString();
        return item;
      });

      this.countries = dataMapped;
    });

    this.logOut = action(
      'log out', () => {
        this.updateProfileData(this.profileInitialState);
        this.updateUserData(this.userInitialState);
    });

    this.updateSpinnerData = action(
      'update status of loading spinner process', (status = true) => {
      if (this.spinnerStatus !== status) {
        this.spinnerStatus = status;
      }
    });

    this.updateProgressData = action(
      'update status of loading process', (status = true) => {
      if (this.progressStatus !== status) {
        this.progressStatus = status;
      }
    });

    this.updateAnalyticsStatus = action(
      'update status of Google Analytics', (status) => {
      if (this.isAnalyticsInitialised !== status) {
        this.isAnalyticsInitialised = status;
      }
    });

    this.updateSidebarStatus = action(
      'toggle login dropdown', (status) => {
      if (this.sidebarStatus !== status) {
        this.sidebarStatus = status;
      }
    });
  }
}

const store = new AppStore();

export default store;
